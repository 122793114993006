import React from 'react';
import './Mapa.scss';

const Comp1 = () => {
	const address = 'Defensa 2207, 11800 Montevideo, Departamento de Montevideo';
	// eslint-disable-next-line
	const lat = -34.8875360421242;
	// eslint-disable-next-line
	const lon = -56.17495446539156;
	const zoom = 20;

	return (
		/* <div className="Mapa">
            <iframe
                src={`https://maps.google.com/maps?q=${lat},${lon}&z=${zoom}&output=embed`}
                width="600"
                height="450"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Espacio Gissel Google Map"
            ></iframe>
        </div> */
		<div>
			<iframe
				width="550"
				height="400"
				loading="lazy"
				src={`https://maps.google.com/maps?q=${address}&t=&zoom=${zoom}&maptype=roadmap&ie=UTF8&iwloc=&output=embed`}
				title="Espacio Gissel GoogleMap"
			></iframe>
		</div>
	);
};

export default Comp1;
