import React, { Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import WppBtn from './Components/General/WppBtn';

import { Home } from './Components/Home';

import { Helmet } from 'react-helmet';
import { Spinner } from 'react-bootstrap';

const About = React.lazy(() => import('./Components/About'));
const Masajes = React.lazy(() => import('./Components/Masajes'));
const TerapiesArom = React.lazy(() =>
	import('./Components/TerapiasAromaterapia')
);
const TerapiesFlower = React.lazy(() => import('./Components/TerapiasFlores'));
const TerapiesBar = React.lazy(() => import('./Components/TerapiasBarras'));
const TerapiesFrec = React.lazy(() =>
	import('./Components/TerapiasFrecuencias')
);
const TerapiesCards = React.lazy(() => import('./Components/TerapiasCartas'));
const TerapiesNumb = React.lazy(() =>
	import('./Components/TerapiasNumerologia')
);

function App() {
	return (
		<>
			<WppBtn />

			<Helmet>
				<title>Espacio Gissel</title>
				<meta
					name="description"
					content="Terapias de medicina holística para despiertar el potencial de sanación interior, trabajar mente, cuerpo y espíritu, lograr bienestar y salud."
				/>
				<meta
					name="keywords"
					content="Masajes, Masajes terapéuticos, Reflexología, Piedras calientes, Masajes Descontracturantes, Maderoterapia, Digitopuntura, Masajes Estéticos con presoterapia, Masajes Reafirmantes con electrodos, 
          Terapias Alternativas, Terapia con Frecuencias, Healy Montevideo, Aromaterapia, Flores de Bach, Flores del Mediterráneo, Terapia Floral, Barras de Access, Cartas natales, Cartas numerológicas, Numerología, Tarot Egipcio"
				/>
			</Helmet>

			<Routes>
				<Route path="/" element={<Home />}></Route>
				<Route path="*" element={<Home />}></Route>
				<Route
					path="/Terapias"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<TerapiesArom />
						</Suspense>
					}
				></Route>
				<Route
					path="/Conoceme"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<About />
						</Suspense>
					}
				></Route>
				<Route
					path="/Masajes"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<Masajes />
						</Suspense>
					}
				></Route>
				<Route
					path="/Terapias/Aromaterapia"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<TerapiesArom />
						</Suspense>
					}
				></Route>
				<Route
					path="/Terapias/Flores"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<TerapiesFlower />
						</Suspense>
					}
				></Route>
				<Route
					path="/Terapias/Barras"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<TerapiesBar />
						</Suspense>
					}
				></Route>
				<Route
					path="/Terapias/Frecuencias"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<TerapiesFrec />
						</Suspense>
					}
				></Route>
				<Route
					path="/Terapias/CartasNatales"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<TerapiesCards />
						</Suspense>
					}
				></Route>
				<Route
					path="/Terapias/Numerologia"
					element={
						<Suspense
							fallback={
								<div className="spinner-container">
									<Spinner animation="border" role="status" variant="primary">
										<span className="sr-only">Loading...</span>
									</Spinner>
								</div>
							}
						>
							<TerapiesNumb />
						</Suspense>
					}
				></Route>
			</Routes>
		</>
	);
}

export default App;
